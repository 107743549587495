.App {
  text-align: center;
}


.App-header {
  background-color: #323232;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(147, 147, 147);
}

p {
    letter-spacing: 15px;
    font-weight: lighter;
}




